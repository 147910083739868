import React, { lazy } from "react";
import { Redirect } from 'react-router-dom';
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/index")),
  },
  {
    exact: true,
    path: "/testing",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/Testing")),
  },
  {
    exact: true,
    path: "/contact",
    layout: HomeLayout,
    component: lazy(() => import("./view/pages/Home/Contact")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];

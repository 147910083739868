// App.js
import { ThemeProvider } from "@mui/material/styles";
import React, { Suspense, Fragment } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MomentUtils from "@date-io/moment";
import PageLoading from "./component/PageLoading";
import { routes } from "./routes";
import { createBrowserHistory } from "history";
import AuthContext from "./context/Auth";
import AuthGuard from "./component/AuthGuard";
// import { createTheme } from "./theme";
import { createCustomTheme } from "./theme/index";

const history = createBrowserHistory();

function App() {
  const theme = createCustomTheme();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider utils={MomentUtils}>
          <AuthContext history={history}>
            <Router history={history}>
              <Suspense fallback={<PageLoading />}>
                <Switch>
                  {routes.map((route, i) => {
                    const Component = route.component;
                    const Guard = route.guard ? AuthGuard : Fragment;
                    const Layout = route.layout || Fragment;

                    return (
                      <Route
                        exact={route.exact}
                        key={i}
                        path={route.path}
                        render={(props) => (
                          <Guard>
                            <Layout>
                              {route.routes ? (
                                <RenderRoutes data={route.routes} history={history} />
                              ) : (
                                <Component {...props} history={history} />
                              )}
                            </Layout>
                          </Guard>
                        )}
                      />
                    );
                  })}
                </Switch>
              </Suspense>
            </Router>
          </AuthContext>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes({ data, history }) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;

          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} history={history} />
                    ) : (
                      <Component {...props} history={history} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

import React from "react";

import {
  Container,
  Button,
  Typography,
  Grid,
  FormControl,
  Input,
} from "@mui/material";
import {
  styled,
  Box,
  fontWeight,
  borderRadius,
  width,
  padding,
  border,
  margin,
  display,
  textAlign,
  fontSize,
} from "@mui/system";
import Logo from "../../component/Logo";

const StyledFooter = styled("Box")(({ theme }) => ({
  "& h6": {
    fontWeight: 400,
    textAlign: "center",
    color: "white",
    fontSize: "12px",
  },
  "& .main_footer": {
    background: "linear-gradient(45deg, #8636DC, #4C2FBE)",
    padding: "20px 0px",
    "& h5": {
      fontWeight: 200,
    },
    "& .signupbx": {
      display: "flex",
      flexDirection: "column",
      background: "#28BDDD",
      borderRadius: "20px",
      padding: "20px",
      "& .emailinput": {
        width: "70%",
        border: "solid 1px black",
        borderRadius: "50px 0px 0px 50px !important",
        padding: "0px 0px 0px 20px",
      },
      "& .btninput": {
        width: "30%",
        border: "solid 1px black",
        borderRadius: "0px 50px 50px 0px !important",
        padding: "0px 20px",
      },
    },
  },
}));

export default function Footer() {
  return (
    <>
      <StyledFooter id="footer">
        <Box className="main_footer">
          <Container maxWidth="lg">
            <Grid container alignItems={"center"}>
              <Grid item lg={4} md={4} sm={12} xs={12} padding={"20px"}>
                <Box>
                  <Logo />
                  <Typography variant="h5" color={"white"}>
                    We are trying to deliver the utmost technology-based
                    services and products. Ensuring a smooth and safe journey
                    with your gadgets and upcoming technology.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12} padding={"20px"}>
                <FormControl>
                  <Box className="signupbx">
                    <Typography variant="h3">
                      Sign up to our newsletter
                    </Typography>
                    <Typography>Receive the latest Technews.</Typography>
                    <Box display={"flex"} margin={"10px 0px"}>
                      <Input
                        placeholder="Type your Email here."
                        className="emailinput"
                      ></Input>
                      <Button className="btninput" variant="containedPrimary">
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12} padding={"20px"}>
                <Box display={"flex"} flexDirection={"column"} color={"white"}>
                  <Typography variant="h3">Contact</Typography>
                  <br />
                  <Typography variant="h5">info@techcide.us</Typography>
                  <Typography variant="h5">+1-6572071411</Typography>
                  <br />
                  <Typography variant="h5">
                    30 North Gould Street, Suite R Sheridan, WY 82801 USA
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Typography variant="h6">© copyright Techcide LLC 2024.</Typography>
        </Box>
      </StyledFooter>
    </>
  );
}

import React from "react";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";
import { styled } from "@mui/system";

const StyledIndex = styled("Box")(({ theme }) => ({
  "& .root": {
    backgroundColor: "#fff",
  },
  "& .MainLayout": {
    minHeight: "calc(100vh - 415px)"
  },
}));


const MainLayout = ({ children }) => {
  const history = useHistory();
  console.log(history.location);
  const scrollToAbout = () => {
    const aboutSection = document.getElementById("ourabout");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToService = () => {
    const serviceSection = document.getElementById("ourservices");
    if (serviceSection) {
      serviceSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToFooter = () => {
    const footerSection = document.getElementById("footer");
    if (footerSection) {
      footerSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <StyledIndex>
      <div className="root">
        <TopBar
          scrollToAbout={scrollToAbout}
          scrollToService={scrollToService}
          scrollToFooter={scrollToFooter}
        />
        <div
          style={
            history.location.pathname !== "/"
              ? { display: "block" }
              : { display: "none" }
          }
        ></div>

        <div className="MainLayout">{children}</div>
        <Footer />
      </div>
    </StyledIndex>

  );
};

export default MainLayout;

export const themeOptions = {
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: "5vw",
      fontFamily: "'Poppins', sans-serif",
      "@media(max-width:900px)": {
        fontSize: "10vw !important",
      },
    },
    h2: {
      fontWeight: 300,
      fontSize: "3.5vw",
      fontFamily: "'Poppins', sans-serif",
      "@media(max-width:900px)": {
        fontSize: "5vw !important",
      },
    },
    h3: {
      fontWeight: 600,
      fontSize: 22,
      fontFamily: "'Poppins', sans-serif",
      "@media(max-width:900px)": {
        fontSize: "20px !important",
      },
    },
    h4: {
      fontWeight: 400,
      fontSize: 16,
      fontFamily: "'Poppins', sans-serif",
      lineHeight: "30px",
      "@media(max-width:900px)": {
        fontSize: "18px !important",
      },
    },
    h5: {
      fontWeight: 400,
      fontSize: 18,
      fontFamily: "'Poppins', sans-serif",

      "@media(max-width:900px)": {
        fontSize: "16px !important",
      },
    },
    h6: {
      fontWeight: 400,
      fontSize: 16,
      fontFamily: "'Poppins', sans-serif",

      "@media(max-width:900px)": {
        fontSize: "14px !important",
      },
    },
    overline: {
      fontWeight: 500,
      fontFamily: "'Poppins', sans-serif",
    },
    button: {
      textTransform: "capitalize",
      borderRadius: 27,
      fontFamily: "'Poppins', sans-serif",
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "25px",
      fontFamily: "'Poppins', sans-serif",

      "@media(max-width:900px)": {
        fontSize: "14px !important",
      },
    },
    body2: {
      fontSize: 14,
      fontWeight: 300,
      fontFamily: "'Poppins', sans-serif",
      lineHeight: "26px",
      "@media(max-width:900px)": {
        fontSize: "12px !important",
      },
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 300,
      fontFamily: "'Poppins', sans-serif",
    },
  },
};

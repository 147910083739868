import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  Container,
  Typography,
  List,
  ListItem,
} from "@mui/material";

import { styled, Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useHistory, } from "react-router-dom";
import Logo from "./../../component/Logo";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { MailOutlineRounded, SearchOutlined } from "@mui/icons-material";
const StyledTopbar = styled("Box")(({ theme }) => ({
  "& .menuButton": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#1ed760",
    },
    "&:hover": {
      color: "#FFBB55",
    },
  },
  "& .toolbar": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: "20px",
    "@media (max-width: 900px)": {
      height: "100%",
    },
    " .toolbar_txt": {
      display: "flex",
      "& h6": {
        paddingLeft: "10px",
      },
    },
  },

  "$ .containerHeight": {
    height: "100%",
  },
  "$ .containerHeight2": {
    height: "100%",
  },

  "& .menubtn": {
    height: "50px",
    width: "auto",
    borderRadius: "20px",
    justifyContent: "space-around",
  },
}));

const headersData = [
  {
    label: "Our Services",
    href: "/",
  },
  {
    label: "About",
    href: "/",
  },
  {
    label: "Contact",
    href: "/",
  },
];

export default function Header({ scrollToAbout, scrollToService, scrollToFooter }) {
  const history = useHistory();
  console.log(history.location);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [area, setArea] = React.useState("");

  const handleChange = (event) => {
    setArea(event.target.value);
  };

  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const handleMenuClick = (menuItem) => {
    if (menuItem.label === "Our Services") {
      scrollToService()
    } else if (menuItem.label === "About") {
      scrollToAbout();
    } else if (menuItem.label === "Contact") {
      scrollToFooter();
    } else {
      history.push(menuItem.link);
    }
  };

  const displayDesktop = () => {
    return (
      <StyledTopbar>
        <Container maxWidth="lg" className="p-0" style={{ padding: "10px" }}>
          <Toolbar className="toolbar">
            <Logo />
            <Box display={"flex"}>
              {headersData.map((header, index) => (
                <Typography
                  padding={"0px 20px"}
                  color={"white"}
                  onClick={() => {
                    history.push(header.href);
                    handleMenuClick(header)
                  }}
                  sx={{
                    cursor: "pointer",
                    transition: "ease-in-out 0.3s",
                    fontWeight: "300",
                  }}
                >
                  {header.label}
                </Typography>
              ))}
            </Box>
            <Typography color={"white"} display={"flex"}>
              <MailOutlineRounded />
              info@techcide.us
            </Typography>
          </Toolbar>
        </Container>
      </StyledTopbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    return (
      <StyledTopbar>
        <Container maxWidth="lg" className="p-0">
          <Toolbar className="toolbar">
            <div>
              <Logo />
            </div>
            <Box display={"flex"}>
              <IconButton className="menubtn" onClick={handleDrawerOpen}>
                <MenuIcon
                  style={{
                    color: "white",
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
          </Toolbar>
          <Drawer
            {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <Box backgroundColor="#4a3dbc" height={"100%"}>
              <Box padding={"20px"}>
                <Logo />
              </Box>
              <Box>
                <Box sx={{ width: 250 }} role="presentation">
                  <List>
                    {headersData.map((Category, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => {
                          handleDrawerClose();
                          history.push(Category.href);
                        }}
                        style={{
                          padding: "30px",
                          color: "white",
                          fontSize: "20px",
                        }}
                      >
                        {Category.label}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Container>
      </StyledTopbar>
    );
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ border: "none" }}
      >
        <Box style={{ backgroundColor: "transparent", border: "none" }}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>
    </>
  );
}

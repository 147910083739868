import React, { createContext, useState } from "react";
import axios from "axios";

export const AuthContext = createContext();

const setSession = (accessToken, history) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // Use history to navigate to a different route if needed
    // Example: history.push("/some-route");
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider({ children, history }) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});

  let data = {
    userLoggedIn: isLogin,
    userData,
    userLogIn: (type, data) => {
      setSession(data, history); // Pass history to setSession
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
  );
}
